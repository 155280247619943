import React from 'react';
import Badge from '../../molecules/Badge/Badge';
import Image from '../../utils/OptimizedImage';
import Link from '../../utils/Link';
import { colors } from '../../styles/atoms/colors';
import {
  Container,
  Wrapper,
  Thumbnail,
  Text,
} from './styles/CaseStudyCard.styled';
import Icon from '../../styles/atoms/icons';

const CaseStudyCard = ({ component }) => (
  <Container>
    <Wrapper>
      <Thumbnail>
        <Image
          image={component?.thumbnail?.gatsbyImageData}
          alt={component?.thumbnail?.alt}
          title={component?.thumbnail?.title}
        />
      </Thumbnail>
      <Text>
        <Badge size="sm" badgeColor={colors.red[500]}>
          General Repair
        </Badge>
        <h2 className="case-study-heading">{component?.title}</h2>
        <p className="case-study-subhead">{component?.excerpt}</p>
        <Link
          to={`/case-studies/${component?.slug}`}
          className="case-study-link"
        >
          Read More
          <Icon id="FiRightArrow" />
        </Link>
      </Text>
    </Wrapper>
  </Container>
);

export default CaseStudyCard;
